import React from "react";
import Breadcrumb from "../../components/contactus/Breadcrumb";
import MapComponent from "./MapComponent";
import "./ContactUs.scss";
import { FaWhatsappSquare } from "react-icons/fa";

export default function ContactUs() {


  const handleEmailClick = () => {
    window.location.href = 'mailto:tkt@chicagohajj.com';
  }
  return (
    <section className="page-contactus">
      {/* Breadcrumb section */}
      <Breadcrumb />
      <div className="section-spacing container">
        <div className="row">
          <div className="col-md-9 col-12 pr-md-3">
            <div className="map-section">
              {/* <img src={MapImg} alt="map image" className="img-fluid"/> */}
              <MapComponent isMarkerShown />
            </div>
            <div className="contact-side-content contact-left-section">
              <div className="contact-item">
                <h4 className="map-title color-primary font-weight-semibold">
                  Mailing Address
                </h4>
                <p className="font-weight-500">
                  <small className="color-secondary font-weight-normal d-block">
                    Location:{" "}
                  </small>
                  2818 W. Peterson Ave Chicago, IL 60659
                </p>
                <p className="font-weight-500">
                  <small className="color-secondary font-weight-normal d-block">
                    Email:
                  </small>
                  <span style={{fontSize:"18px",textDecoration: 'underline', color: 'blue', cursor: 'pointer'}} onClick={handleEmailClick}>tkt@chicagohajj.com</span>
                </p>
              </div>
              <div className="contact-item">
                <h4 className="map-title color-primary font-weight-semibold">
                  Other Locations: Chicago Hajj & Umrah
                </h4>
                <p  className="font-weight-500 color-secondary">
                  <small className="color-secondary font-weight-normal d-block">
                    Makkah Branch (Saudi Arabia){" "}
                  </small>
                  Al Rusaifa Near Nakheel Plaza Makkah al Mukkaramah<br/>MOFA-Services<br/>
                  Riyadh,SA
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-12 contact-right-section">
            <div className="contact-side-content">
              <div className="contact-item">
                <h4 className="map-title color-primary font-weight-semibold">
                  Reservation/Inquiry Help Number
                </h4>
                <div className="font-weight-500"> 
                  <small className="color-secondary font-weight-normal d-block">
                  Office:{" "}
                  </small>
                 <div style={{display:"flex",alignItems:"center",gap:"5px"}}>
                 <FaWhatsappSquare size={22} color={"#25D366"} /><h6 style={{margin:0}}>(773) 465-8500</h6>
                 </div>
                </div>
                <p class="font-weight-500"><small class="color-secondary font-weight-normal d-block">Cell: </small>(773) 680-4700</p>
                
                {/* <p className="font-weight-500">
                  <small className="color-secondary font-weight-normal d-block">
                    Hajj Dept.:{" "}
                  </small>
                  Exe. 1
                </p>
                <p className="font-weight-500">
                  <small className="color-secondary font-weight-normal d-block">
                    Visa Dept.:{" "}
                  </small>
                  Exe. 2
                </p>
                <p className="font-weight-500">
                  <small className="color-secondary font-weight-normal d-block">
                    Ticket & Umrah Package info:{" "}
                  </small>
                  Exe. 3
                </p> 
                <p className="font-weight-500">
                  <small className="color-secondary font-weight-normal d-block">
                    Ticket & Umrah Package info:{" "}
                  </small>
                  Exe. 3
                </p> */}
              </div>
              <div className="contact-item">
                <h4 className="map-title color-primary font-weight-semibold">
                  Business Hours
                </h4>

                <p className="font-weight-500">
                  <small className="color-secondary font-weight-normal d-block">
                    Monday - Friday{" "}
                  </small>
                  10:00am to 10:00pm (CST)
                </p>
                <p className="font-weight-500">
                  <small className="color-secondary font-weight-normal d-block">
                    Saturday
                  </small>
                  10:00am to 10:00pm (CST)
                </p>
                <p className="font-weight-500">
                  <small className="color-secondary font-weight-normal d-block">
                    Sunday
                  </small>
                  Closed
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
